import moment from 'moment';
import { Header } from '../../../Shared/Components/Header/Header.tsx';
import { Section } from '../../../Shared/Components/Section/Section.tsx';
import { Days } from '../../Components/Days/Days.tsx';
import { TimeSlot } from '../../Components/TimeSlot/TimeSlot.tsx';
import {
  Key,
  TimePeriod,
  TimeSlotAttributes,
  TimeSlot as TimeSlotInterface,
} from '../../../Shared/Services/TimefitBackend';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../../Shared/Components/Loading/Loading.tsx';
import { useCurrentUser } from '../../../Shared/Hooks/useCurrentUser/useCurrentUser.ts';
import { byHour, byPeriod, getTimePeriod, getUnitSchedule } from '../../../Shared/Services/Utils/Utils.ts';
import { path } from '../../../Shared/Services/Path/Path.ts';
import { useSchedule } from '../../Hooks/useSchedule/useSchedule.ts';
import { useSelectedDayKey } from '../../Hooks/useSelectedDayKey/useSelectedDayKey.ts';

export function Show() {
  const { record: currentUser } = useCurrentUser();
  const selectedDayKey = useSelectedDayKey();
  const { unit } = useParams();
  const { record: schedule } = useSchedule();
  const navigate = useNavigate();

  const selectedDayInThePast = moment(selectedDayKey, 'd').isBefore(moment(), 'day');

  const unitSchedule = schedule ? getUnitSchedule(schedule, unit!) : schedule;

  const handleEdit = () => {
    navigate(generatePath(path.scheduling.edit, { day: selectedDayKey, unit }));
  };

  const renderEditAction = () => {
    if (currentUser?.admin) {
      return <span onClick={handleEdit}>Editar</span>;
    }
  };

  const getTimeSlot = (key: Key, timeSlotAttributes: TimeSlotAttributes): TimeSlotInterface => {
    return {
      key: {
        day: selectedDayKey,
        timePeriod: timeSlotAttributes.timePeriod,
        key,
      },
      ...timeSlotAttributes,
    };
  };

  const renderTimeSlots = () => {
    if (!unitSchedule) {
      return <Loading />;
    }

    const selectedDay = unitSchedule[selectedDayKey];

    if (!selectedDay || (selectedDayInThePast && !currentUser?.admin)) {
      return <Section label="Nenhum horário disponível para este dia." />;
    }

    const sortedTimePeriods = Object.entries(selectedDay).sort(byPeriod);

    return sortedTimePeriods.map(([timePeriod, timeSlots]) => {
      const sortedTimeSlots = Object.entries(timeSlots!).sort(byHour);

      return (
        <Section key={timePeriod} label={getTimePeriod(timePeriod as TimePeriod)}>
          {sortedTimeSlots.map(([key, timeSlot]) => (
            <TimeSlot key={key} timeSlot={getTimeSlot(key, timeSlot)} />
          ))}
        </Section>
      );
    });
  };

  const getTitle = () => {
    return {
      'jardim-europa': 'Jardim Europa',
      'arena-calu': 'Arena Calu',
    }[unit!];
  };

  return (
    <>
      <Header title={`Agenda - ${getTitle()}`} action={renderEditAction()} />
      <Days />
      {renderTimeSlots()}
    </>
  );
}
